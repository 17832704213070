export default function authenticated({
  next,
  axios}) {
  const role = localStorage.getItem('role');
  console.log(role, [3, 6, 12, 17].indexOf(parseInt(role, 10)) > -1);
  if ([3, 6, 12, 17].indexOf(parseInt(role, 10)) > -1) {
    return next();
  } else {
    try {
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      return next({
        path: '/login'
      });
    } catch (e) {
      console.log(e);
    }
  }
}